.layout__main__container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.layout__sub__container {
  width: 50vw;
}

.layout__sub__container__wide {
  width: 65vw;
}
